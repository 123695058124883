
// pv 指页面浏览量或点击量
const PVTrack = (content_url) => {
  const origin = window.location.origin;
  window._czc && window._czc.push(['_trackPageview', content_url, `${origin}${content_url}`]);
}

// 交互事件
const ClickTrack = (category, action, label) => {
  window._czc && window._czc.push(['_trackEvent', category, action, label]);
}

export { PVTrack, ClickTrack };