const prefix = "/apiInterface/interface";
module.exports = {
  //发送验证码
  SEND_CODE: `${prefix}/hydra-user/api/v1/sms/send/phone/code`,
  //登录
  WEB_LOGIN: `${prefix}/hydra-user/api/v1/user/web/login`,
  //登出
  WEB_LOGOUT: `${prefix}/hydra-user/api/v1/user/web/logout`,
  //开通人员认证
  USER_ATP_AUTH: `${prefix}/hydra-user/api/v1/user-atp-user-auth`,

  //校验用户手机号是否已开通过系统
  CHECK_PHONE: `${prefix}/hydra-user/api/v1/user/check/phone/`,
  //获取验证码的token的接口
  CODE_TOKEN: `${prefix}/hydra-user/api/v1/code/code-token`,
  //人机验证码图片
  CODE_URL: `${prefix}/hydra-user/api/v1/code/abc/`,
  //校验图形验证码
  CHECK_ABC_CODE: `${prefix}/hydra-user/api/v1/code/check/abc-code`,
  //校验验证码是否正确
  CHECK_PHONE_CODE: `${prefix}/hydra-user/api/v1/sms/check/phone-code`,
  //忘记密码
  FORGOT_PASSWORD: `${prefix}/hydra-user/api/v1/user/forgot-password`,

  //获取用户免费体验状态
  USER_STATUS: `${prefix}/hydra-cjm-cloud-portal/api/v1/free-trial/user/status`,
  //免费体验的时候企业信息登记
  ORG_REGISTER: `${prefix}/hydra-cjm-cloud-portal/api/v1/free-trial/org/register`,

  //登录轮播图列表
  CAROUSEL_LIST: `${prefix}/hydra-cjm-cloud-portal/api/v1/carousel/list`,

  //套餐显示接口
  PRODUCT_INDEX: `${prefix}/hydra-cjm-cloud-portal/api/v1/product/index`,
  //套餐开通状态
  OPEN_STATUS: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/open-status`,
  //认证状态
  AUTH_STATUS: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/status`,
  //获取登入人的企业认证详情
  YOURSELF_DETAIL: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/yourself/detail`,
  //套餐选择
  PACKAGE_CHOOSE: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/choose`,
  //跳过企业认证
  SKIP_AUTH: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/skip-auth`,
  //企业信息认证
  AUTH_ORG: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/info`,
  //企业信息核验，填写法人信息
  AUTH_ORG_CHECK: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/check`,
  //企业对公打款信息核验
  AUTH_ORG_PAYMENT: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/org/public-payment`,
  //银行名称搜索
  BANK_SEARCH: `${prefix}/hydra-user/api/v1/atp-bank/page`,
  //校验对工转账金额的状态
  AUTH_CHECK_AMOUNT_STATUS: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/check-amount/status`,
  //校验对工转账金额
  AUTH_CHECK_AMOUNT: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/check-amount`,
  //认证完成
  AUTH_FINISH: `${prefix}/hydra-cjm-cloud-portal/api/v1/auth/finish`,

  //获取当前用户选择的开通套餐内容
  OPEN_CHOOSE_INFO: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/choose-info`,

  //查询续费信息
  PACKAGE_DETAIL_WEBSITE: `${prefix}/hydra-cjm-cloud-portal/api/v1/renewal/package/detail`,
  //企业对应付费功能使用详情
  CAPACITY_DETAIL: `${prefix}/hydra-cjm-cloud-portal/api/v1/system/renewal/fun-type-of-bill/capacity/detail`,
  //官网创建续费套餐订单
  RENEWAL_PACKAGE_CREATE: `${prefix}/hydra-cjm-cloud-portal/api/v1/renewal/order/create`,

  //创建开通组织套餐订单
  CREATE_ORDER: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/create-order`,
  //取消订单（放弃支付）
  CANCEL_ORDER: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/renounce-pay`,
  //订单发起支付
  CREATE_PAY: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/create-pay`,
  //查询支付状态
  PAY_STATUS: `${prefix}/hydra-cjm-cloud-portal/api/v1/trade/pay/status`,
  //查询登入用户下的券信息
  COUPON_USER_LIST: `${prefix}/hydra-cjm-cloud-portal/api/v1/order/coupon/list`,
  //支付完成后开通组织
  OPEN_ORG: `${prefix}/hydra-cjm-cloud-portal/api/v1/package/open/open-org`,
  // 创建交易（非开通套餐）
  TRADE_CREATE_PAY: `${prefix}/hydra-cjm-cloud-portal/api/v1/trade/create-pay`,
  // 功能定制
  FUNCTION: `${prefix}/trace/dynamic/fun/list/noToken`,
  // 页面访问量统计
  PAGE_NUM: `${prefix}/hydra-data-point/api/v1/data-point/add`,
};
// module.exports = {
//     //发送验证码
//     SEND_CODE: `${prefix}/hydra-user/api/v1/sms/send/phone/code`,
//     //登录
//     WEB_LOGIN: `${prefix}/hydra-user/api/v1/user/web/login`,
//     //登出
//     WEB_LOGOUT: `${prefix}/hydra-user/api/v1/user/web/logout`,

//     //获取用户免费体验状态
//     USER_STATUS: `${prefix}/hydra-user/api/v1/atp-free-trial/user/status`,
//     //免费体验的时候企业信息登记
//     ORG_REGISTER: `${prefix}/hydra-user/api/v1/atp-free-trial/org/register`,

//     //校验用户手机号是否已开通过系统
//     CHECK_PHONE: `${prefix}/hydra-user/api/v1/user/check/phone/`,
//     //获取验证码的token的接口
//     CODE_TOKEN: `${prefix}/hydra-user/api/v1/code/code-token`,
//     //人机验证码图片
//     CODE_URL:`${prefix}/hydra-user/api/v1/code/abc/`,
//     //校验图形验证码
//     CHECK_ABC_CODE: `${prefix}/hydra-user/api/v1/code/check/abc-code`,
//     //校验验证码是否正确
//     CHECK_PHONE_CODE: `${prefix}/hydra-user/api/v1/sms/check/phone-code`,
//     //忘记密码
//     FORGOT_PASSWORD: `${prefix}/hydra-user/api/v1/user/forgot-password`,
//     //登录轮播图列表
//     CAROUSEL_LIST: `${prefix}/hydra-user/api/v1/atp-carousel/website/list`,

//     //套餐开通状态
//     OPEN_STATUS: `${prefix}/hydra-user/api/v1/atp-package/open-status`,
//     //认证状态
//     AUTH_STATUS: `${prefix}/hydra-user/api/v1/atp-org-auth/status`,
//     //获取赠送功能详情
//     PACKAGE_FREE_DETAILS: `${prefix}/hydra-user/api/v1/user-atp-package-free-fun/details`,
//     //套餐内容
//     ATP_PACKAGE_DETAIL: `${prefix}/hydra-user/api/v1/atp-package/detail`,
//     //获取登入人的企业认证详情
//     YOURSELF_DETAIL: `${prefix}/hydra-user/api/v1/atp-org-auth/yourself/detail`,
//     //套餐选择
//     PACKAGE_CHOOSE: `${prefix}/hydra-user/api/v1/atp-package/choose`,
//     //开通人员认证
//     USER_ATP_AUTH: `${prefix}/hydra-user/api/v1/user-atp-user-auth`,
//     //跳过企业认证
//     SKIP_AUTH: `${prefix}/hydra-user/api/v1/atp-org-auth/skip-auth`,
//     //企业信息认证
//     AUTH_ORG: `${prefix}/hydra-user/api/v1/atp-org-auth/auth/org`,
//     //修改企业认证信息-名称以及社会信用代码
//     CHANGE_OTHER_INFO: `${prefix}/hydra-user/api/v1/atp-org-auth/change-other-info`,
//     //企业信息核验，填写法人信息
//     AUTH_ORG_CHECK: `${prefix}/hydra-user/api/v1/atp-org-auth/org/check`,
//     //企业对公打款信息核验
//     AUTH_ORG_PAYMENT: `${prefix}/hydra-user/api/v1/atp-org-auth/org/public-payment`,
//     //银行名称搜索
//     BANK_SEARCH: `${prefix}/hydra-user/api/v1/atp-bank/page`,
//     //校验对工转账金额的状态
//     AUTH_CHECK_AMOUNT_STATUS: `${prefix}/hydra-user/api/v1/atp-org-auth/check-amount/status`,
//     //校验对工转账金额
//     AUTH_CHECK_AMOUNT: `${prefix}/hydra-user/api/v1/atp-org-auth/check-amount`,

//     //查询续费信息
//     PACKAGE_DETAIL_WEBSITE: `${prefix}/hydra-user/api/v1/atp-org-package/detail/in-official-website`,
//     //对应套餐需要附带显示可选购买付费功能列表
//     SHOPPING_DETAIL: `${prefix}/hydra-user/api/v1/atp-package-fun/detail-of-to-show-in-shopping`,
//     //企业对应付费功能使用详情
//     CAPACITY_DETAIL: `${prefix}/hydra-user/api/v1/atp-org-package-fun/fun-type-of-bill/capacity/detail`,
//     //官网创建续费套餐订单
//     RENEWAL_PACKAGE_CREATE: `${prefix}/hydra-user/api/v1/atp-order/official-website/renewal-package/create`,

//     //创建开通组织套餐订单
//     CREATE_ORDER: `${prefix}/hydra-user/api/v1/atp-package/create-order`,
//     //取消订单（放弃支付）
//     CANCEL_ORDER: `${prefix}/hydra-user/api/v1/atp-package/cancel/order`,
//     //获取订单详情
//     ATP_ORDER_DETAIL: `${prefix}/hydra-user/api/v1/atp-order/detail`,
//     //订单发起支付
//     CREATE_PAY: `${prefix}/hydra-user/api/v1/atp-order/create-pay`,
//     //查询支付状态
//     PAY_STATUS: `${prefix}/hydra-user/api/v1/atp-order/pay/status`,
//     //查询登入用户下的券信息
//     COUPON_USER_LIST: `${prefix}/hydra-user/api/v1/atp-coupon/user/list`,
// };
