import { packageDetailWebsite, createOrder } from "../services/website";
import { setOrderStorage } from "src/static/utils/orderStorage";
import { jumpPage } from "src/static/utils/utils.js";

export function skipFunc(results) {
  switch (results.status) {
    case "-1":
      //跳转购买套餐页面
      // window.location.href = "chooseDeloy.html";
      jumpPage('/chooseDeloy.html')
      break;
    case "0":
    //初始状态，已申请购买套餐，资料已提供，未进行校验；  跳转填写企业信息认证页面， 先获取已填写认证信息，提供给用户编辑， 在下一步
    case "1":
    //用户企业认证已通过； 跳转订单页面
    case "2":
    //管理员驳回
    case "3":
    //实名认证已冻结
    case "4":
    //款金额错误
    case "5":
    //信息核验不正确
    case "6":
    //校验金额，对公转账金额暂未开始校验；跳转校验金额页面
    case "8":
    //企业信息核验，跳转到企业信息核验页面
    case "9":
      //对公打款认证；跳转对公打款认证页面
      // window.location.href = "openCertification.html";
      jumpPage('/openCertification.html')
      break;
    case "a":
      //跳过认证，跳转到订单页面
      createOrderFunc();
      break;
    case "400":
      //订单放弃支付，且未来得及重新选择套餐,需要跳转开通套餐页面;
      window.location.href = "chooseDeloy.html?type=reselect";
      break;
    case "500":
    //订单支付成功，且未来得及开通组织,需要调开通组织接口，重新开通组织;
    case "600":
      //订单未支付，且交易为待完成,需要倒计时查询交易状态;
      orderCreate("payment.html", results.value);
      break;
    case "700":
    //订单未支付，交易关闭状态，需要重新发起交易
    case "800":
      //订单未支付，交易未开启，需要重新发起交易
      createOrderFunc();
      break;
    case "900":
      //套餐过期
      getOldOrgDetail()
      break;
    case "1000":
      //成功状态
      window.open(`${results.redirectUrl}/apiInterface/interface/hydra-user/api/v1/user/refresh-and-redirect?token=${results.value}`);
      // setTimeout(() => {
      //   window.location.href = "platform.html";
      // }, 1000)
      break;
    case "999":
      // 异常处理
      ELEMENT.MessageBox.confirm("系统开通中，预计24小时内开通成功，如有疑问，联系客服咨询，客服电话：18958077257", {
        closeOnClickModal: false,
        center: true
      })
        .then(() => { }).catch(() => { })
      break;
    default:
      //其他情况，跳转首页
      // if (window.top !== window.self) return;
      // window.location.href = "platform.html";
  }
}
function orderCreate(url, val) {
  if (val != "") {
    localStorage.setItem("orderNo", val);
    window.location.href = url;
  } else {
    ELEMENT.Message.warning("订单号为空,无法跳转");
  }
}

//获取过期企业信息
async function getOldOrgDetail() {
  const res = await packageDetailWebsite();
  if (res.state == 200) {
    if (res.results.packageId != 1) {
      localStorage.setItem('oldPackageId', res.results.packageId);
      ELEMENT.MessageBox.confirm("您的系统已过期，是否续费？", {
        closeOnClickModal: false,
        cancelButtonText: "暂时不需要",
        confirmButtonText: "续费",
        showClose: false,
        center: true
      })
        .then(() => {
          window.location.href = "renewalSystem.html";
        })
        .catch(() => { })
    } else {
      ELEMENT.MessageBox.confirm("体验套餐已过期，请联系客服人员：18958077257", {
        closeOnClickModal: false,
        center: true
      })
        .then(() => { }).catch(() => { })
    }
  }
}

async function createOrderFunc() {
  setOrderStorage()
  return;
  const res = await createOrder({});
  if (res.state == 200) {
    localStorage.setItem("orderNo", res.results.orderNo);
    window.location.href = "confirmOrder.html";
  }
}