import { PackageDetails,openChooseInfo } from "../services/website";
/**
* 设置开通套餐订单信息
* */
export const setOrderStorage = async () => {
    const selfData = await openChooseInfo();
    let packageId = null;
    console.log('selfData',selfData);
    if(selfData.state == 200){
        packageId = selfData.results.packageId
    }else{
        ELEMENT.Message.warning("套餐ID为空");
        return ;
    }
    let orderData = {
        orderDetailList: [],
        totalPrice: 0,
        propsData: {}
    };
    const res = await PackageDetails({productId:packageId})
    if (res.state == 200) {
        const data = res.results.list && res.results.list[0];
        orderData.totalPrice = (data.price*1).toFixed(2);
        orderData.orderDetailList.push({
            defaultNum: 365,
            orderType: "0",
            productCn: 1,
            productName: data.productName,
            productPrice: data.price,
            productTotalPrice: data.price,
            unit: "天",
        })
        if(data.openOrg > 0){
            orderData.orderDetailList.push({
                defaultNum: 1,
                orderType: "1",
                productCn: data.openOrg,
                productName: "企业券",
                productPrice: 0,
                productTotalPrice: 0,
                unit: "张"
            })
        }
        if(data.codeTotal > 0){
            orderData.orderDetailList.push({
                defaultNum: 1,
                orderType: "1",
                productCn: data.codeTotal,
                productName: "赋码量",
                productPrice: 0,
                productTotalPrice: 0,
                unit: "个"
            })
        }
    }
    orderData.packageId = packageId;
    console.log('存储数据orderData',orderData);
    localStorage.setItem('orderData',JSON.stringify(orderData));
    window.location.href = "confirmOrder.html";
};

/**
* 设置续费订单信息
* */
export const setRenewStorage = async (renewalData) => {
    let orderData = {
        orderDetailList: [],
        totalPrice: (renewalData.price*1).toFixed(2),
        propsData: renewalData.propsData
    };
    const res = await PackageDetails({productId:renewalData.packageId})
    if (res.state == 200) {
        const data = res.results.list && res.results.list[0];
        orderData.orderDetailList.push({
            defaultNum: 365,
            orderType: "2",
            productCn: renewalData.propsData.packageTime,
            productName: data.productName,
            productPrice: data.price,
            productTotalPrice: data.price * renewalData.propsData.packageTime,
            unit: "天",
        })
    }
    if(renewalData.propsData.orgNumber){
        orderData.orderDetailList.push({
            defaultNum: 1,
            orderType: "1",
            productCn: renewalData.propsData.orgNumber,
            productName: "企业券",
            productPrice: renewalData.firmPrice,
            productTotalPrice: renewalData.firmPrice * renewalData.propsData.orgNumber,
            unit: "家"
        })
    }
    if(renewalData.propsData.codeNumber){
        orderData.orderDetailList.push({
            defaultNum: 1,
            orderType: "1",
            productCn: renewalData.propsData.codeNumber,
            productName: "赋码量",
            productPrice: renewalData.codePrice,
            productTotalPrice: renewalData.codePrice * renewalData.propsData.codeNumber,
            unit: "个"
        })
    }
    orderData.packageId = renewalData.packageId;
    orderData.propsData.packageTime = renewalData.propsData.packageTime * 365;
    console.log('存储续费数据orderData',orderData);
    localStorage.setItem('orderData',JSON.stringify(orderData));
    window.location.href="confirmOrder.html?type=renewal";
};