/**
* cookie中存值
* */
export const setCookie = (c_name, value, expireseconds) => {
    if (value) {
        var exdate = new Date();
        exdate.setTime(exdate.getTime() + expireseconds * 1000);
        document.cookie = c_name + "=" + escape(value) + ((expireseconds == null) ? "" : ";path=/;expires=" + exdate.toUTCString()) + ';SameSite=None;Secure;'
    }
};

/**
* cookie中取值
* */

export const getCookie = name => {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)"); //匹配字段
    if (arr = document.cookie.match(reg)) {
        return unescape(arr[2]);
    } else {
        return null;
    }
};

/**
* 清除指定cookie值
* */
export const delCookie = (name) => {
    let keys = document.cookie.match(/[^ =;]+(?==)/g)
    const domains = ['.kf315.net', '.jgwcjm.com', document.domain];
    keys && domains.forEach(domain => {
        for (let i = keys.length; i--;) {
            document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString()
            document.cookie = keys[i] + '=0;path=/;domain=' + domain[0] + ';expires=' + new Date(0).toUTCString()
            document.cookie = keys[i] + '=0;path=/;domain=' + domain[1] + ';expires=' + new Date(0).toUTCString()
            document.cookie = keys[i] + '=0;path=/;domain=' + domain[2] + ';expires=' + new Date(0).toUTCString()
        }
    });
};

/**
* 清除全部cookie值
* */

export const clearCookie = () => {
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
    if (keys) {
        for (var i = keys.length; i--;) {
            // document.cookie = keys[i] +'=0;expires=' + new Date( 0).toUTCString()
            document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() + ";path=/video_learning" + ";domain=localhost";
        }
    }
};