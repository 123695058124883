const ajax = require("../utils/ajax");
const api = require("../api/webApi.js");
import { delCookie } from "../utils/cookie.js";
import { jumpPage } from "src/static/utils/utils.js";

//发送验证码
export const sendCode = async (params) => {
  const { res, err } = await ajax.post(api.SEND_CODE, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//校验手机验证码是否正确
export const checkPhoneCode = async (params) => {
  const { res, err } = await ajax.get(api.CHECK_PHONE_CODE, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//校验用户手机号是否已开通过系统
export const checkPhone = async (params) => {
  const { res, err } = await ajax.get(api.CHECK_PHONE + params);
  return res;
};
//获取图片验证码token
export const codeToken = async () => {
  const { res, err } = await ajax.get(api.CODE_TOKEN);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//获取图片验证码
export const codeUrl = (codeToken) => {
  return api.CODE_URL + codeToken + "?nocache=" + Date.now();
};
//校验图形验证码
export const checkAbcCode = async (params) => {
  const { res, err } = await ajax.get(api.CHECK_ABC_CODE, params);
  return res;
};
//修改密码
export const forgotPassword = async (params) => {
  const { res, err } = await ajax.put(api.FORGOT_PASSWORD, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//登录
export const webLogin = async (params) => {
  const { res, err } = await ajax.post(api.WEB_LOGIN, params);
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};
//退出登录
export const webLogout = async () => {
  const { res, err } = await ajax.get(api.WEB_LOGOUT);
  localStorage.clear();
  delCookie();
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//轮播图列表
export const carouselList = async (params) => {
  const { res, err } = await ajax.post(api.CAROUSEL_LIST, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//获取套餐列表
export const PackageDetails = async (params) => {
  const { res, err } = await ajax.get(api.PRODUCT_INDEX, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//获取用户免费体验的状态
export const userStatus = async () => {
  const { res, err } = await ajax.get(api.USER_STATUS);
  if (res.state == 401) {
    // 返回登录异常,清除本地cookie，跳转登录页
    localStorage.clear();
    delCookie();
    jumpPage("/login.html")
  }
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//免费体验企业信息登记
export const orgRegister = async (params) => {
  const { res, err } = await ajax.post(api.ORG_REGISTER, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//套餐开通状态
export const openStatus = async () => {
  const { res, err } = await ajax.get(api.OPEN_STATUS);
  if (res.state == 401) {
    // 返回登录异常,清除本地cookie，跳转登录页
    localStorage.clear();
    delCookie();
    jumpPage("/login.html")
  }
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//套餐认证状态
export const authStatus = async () => {
  const { res, err } = await ajax.get(api.AUTH_STATUS);
  if (res.state == 401) {
    // 返回登录异常,清除本地cookie，跳转登录页
    localStorage.clear();
    delCookie();
    jumpPage("/login.html")
  }
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//查询续费信息
export const packageDetailWebsite = async (params) => {
  const { res, err } = await ajax.get(api.PACKAGE_DETAIL_WEBSITE, params);
  if (res.state == 401) {
    // 返回登录异常,清除本地cookie，跳转登录页
    localStorage.clear();
    delCookie();
    jumpPage("/login.html")
  }
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//查询企业对应付费功能使用详情
export const capacityDetail = async (params) => {
  const { res, err } = await ajax.get(api.CAPACITY_DETAIL, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//创建续费订单
export const renewalPackageCreate = async (params) => {
  const { res, err } = await ajax.post(api.RENEWAL_PACKAGE_CREATE, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//创建订单（开通套餐）
export const createOrder = async (params) => {
  const { res, err } = await ajax.post(api.CREATE_ORDER, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//取消订单
export const cancelOrder = async (params) => {
  const { res, err } = await ajax.post(api.CANCEL_ORDER, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//订单发起支付
export const createPay = async (params) => {
  const { res, err } = await ajax.post(api.CREATE_PAY, params);
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};
//订单支付状态查询
export const payStatus = async (params) => {
  const { res, err } = await ajax.get(api.PAY_STATUS, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//用户认证
export const userAtpAuth = async (params) => {
  const { res, err } = await ajax.post(api.USER_ATP_AUTH, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//跳过企业认证
export const skipAuth = async (params) => {
  const { res, err } = await ajax.post(api.SKIP_AUTH, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//获取登入人的企业认证详情
export const yourselfDetail = async (params) => {
  const { res, err } = await ajax.get(api.YOURSELF_DETAIL, params);
  if (res.state == 401) {
    // 返回登录异常,清除本地cookie，跳转登录页
    localStorage.clear();
    delCookie();
    jumpPage("/login.html")
  }
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};
//企业信息认证
export const authOrg = async (params) => {
  const { res, err } = await ajax.post(api.AUTH_ORG, params);
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};
//企业法人验证
export const authOrgCheck = async (params) => {
  const { res, err } = await ajax.post(api.AUTH_ORG_CHECK, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//银行卡验证
export const authOrgPayment = async (params) => {
  const { res, err } = await ajax.post(api.AUTH_ORG_PAYMENT, params);
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};
//校验对工转账金额状态
export const bankSearch = async (params) => {
  const { res, err } = await ajax.get(api.BANK_SEARCH, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//校验对工转账金额状态
export const authCheckAmountState = async (params) => {
  const { res, err } = await ajax.get(api.AUTH_CHECK_AMOUNT_STATUS, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//校验对工转账金额
export const authCheckAmount = async (params) => {
  const { res, err } = await ajax.post(api.AUTH_CHECK_AMOUNT, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//完成认证
export const authFinish = async (params) => {
  const { res, err } = await ajax.get(api.AUTH_FINISH, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
//套餐选择
export const packageChoose = async (params) => {
  const { res, err } = await ajax.post(api.PACKAGE_CHOOSE, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};

//查询登入用户下的券信息
export const couponUserList = async (params) => {
  const { res, err } = await ajax.get(api.COUPON_USER_LIST, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};

//支付完成后开通组织
export const openOrg = async (params) => {
  const { res, err } = await ajax.post(api.OPEN_ORG, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};

//获取当前用户选择的开通套餐内容
export const openChooseInfo = async (params) => {
  const { res, err } = await ajax.get(api.OPEN_CHOOSE_INFO, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};

//订单发起支付(非开通套餐)
export const tradeCreatePay = async (params) => {
  const { res, err } = await ajax.post(api.TRADE_CREATE_PAY, params);
  // (err && window.ELEMENT)&& ELEMENT.Message.warning(err);
  return res;
};

//功能定制获取关于我们数据（根据不同的functionId）
export const functionData = async (params) => {
  const { res, err } = await ajax.get(api.FUNCTION, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};

//获取页面访问量
export const pageData = async (params) => {
  const { res, err } = await ajax.post(api.PAGE_NUM, params);
  (err && window.ELEMENT) && ELEMENT.Message.warning(err);
  return res;
};
