const _ = require('lodash');
const isDev = window.location.href.indexOf('localhost') >= 0 || window.location.href.indexOf('192.168') >= 0;

const getToken = () => {
  let token = '';
  try {
    token = localStorage.getItem('super-token');
  } catch (error) {
  }
  return token;
}

module.exports = {
  get(url, params, options) {
    options = options || {};
    return new Promise(resolve => {
      let [err, res] = [null, null];
      $.ajaxSetup({ cache: false });
      $.ajax({
        type: 'get',
        url,
        data: params,
        dataType: 'json',
        beforeSend: function (request) {
          getToken() && request.setRequestHeader("super-token", getToken());
          isDev ?
            request.setRequestHeader("whitelist", 'localhost') :
            ''
        },
        success: function (result, status, xhr) {
          res = result;
          const state = _.get(res, 'state');
          const msg = _.get(res, 'msg');
          String(state) !== '200' && (err = msg);
          resolve({ err, res });
        },
        error: function (xhr, status, error) {
          err = error;
          resolve({ err, res });
        }
      });
    });
  },
  post(url, params) {
    return new Promise(resolve => {
      $.ajaxSetup({ cache: false });
      let [err, res] = [null, null];
      $.ajax({
        type: 'post', url,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: params ? JSON.stringify(params) : {},
        beforeSend: function (request) {
          getToken() && request.setRequestHeader("super-token", getToken());
          isDev ?
            request.setRequestHeader("whitelist", 'localhost') :
            ''
        },
        success: function (result, status, xhr) {
          res = result;
          const state = _.get(res, 'state');
          const msg = _.get(res, 'msg');
          String(state) !== '200' && (err = msg);
          resolve({ err, res });
        },
        error: function (xhr, status, error) {
          err = error;
          resolve({ err, res });
        }
      });
    });
  },
  put(url, params) {
    return new Promise(resolve => {
      $.ajaxSetup({ cache: false });
      let [err, res] = [null, null];
      $.ajax({
        type: 'put', url,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: params ? JSON.stringify(params) : {},
        beforeSend: function (request) {
          getToken() && request.setRequestHeader("super-token", getToken());
          isDev ?
            request.setRequestHeader("whitelist", 'localhost') :
            ''
        },
        success: function (result, status, xhr) {
          res = result;
          const state = _.get(res, 'state');
          const msg = _.get(res, 'msg');
          String(state) !== '200' && (err = msg);
          resolve({ err, res });
        },
        error: function (xhr, status, error) {
          err = error;
          resolve({ err, res });
        }
      });
    });
  }
};