/**
* 判断是否在微信
* */

export const is_weixn = () => {
    // 是否是本地开发环境
    const isDev =
        location.href.indexOf("http://localhost") === 0 ||
        location.href.indexOf("http://192.168") === 0 ||
        location.href.indexOf("http://127.0.0.1") === 0;
    // 是否是远程开发环境
    const isRemoteDev =
        location.href.indexOf(".kf315.net") >= 0 &&
        location.href.indexOf("dev-") >= 0;
    // 是否是测试环境
    const isTesting = !isRemoteDev && location.href.indexOf(".kf315.net") >= 0;
    // 是否是生产环境
    const isProduction = !(isDev || isRemoteDev || isTesting);

    if (isProduction) {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

/**
* 判断当前环境是否为 正式环境
* */

export const isOfficial = () => {
    // 是否是本地开发环境
    const isDev =
        location.href.indexOf("http://localhost") === 0 ||
        location.href.indexOf("http://192.168") === 0 ||
        location.href.indexOf("http://127.0.0.1") === 0;
    // 是否是远程开发环境
    const isRemoteDev =
        location.href.indexOf(".kf315.net") >= 0 &&
        location.href.indexOf("dev-") >= 0;
    // 是否是测试环境
    const isTesting = !isRemoteDev && location.href.indexOf(".kf315.net") >= 0;
    // 是否是生产环境
    const isProduction = !(isDev || isRemoteDev || isTesting);

    if (isProduction) {
        return true;
    } else {
        return false;
    }
}

/* 数字 格式化*/
export function numberFormatter(number, places, thousand, symbol) {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    thousand = thousand || ",";
    symbol = symbol !== undefined ? symbol : "";
    let negative = number < 0 ? "-" : "", i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "", j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand);
}

/* 判断折扣券是否在有效期内 */
export const isValidity = (startTime, endTime) => {
    if (startTime == 0 || endTime == 0) return true;
    let start = new Date(startTime.substring(0, 10).replace(/-/g, "/")).getTime(),
        end = new Date(endTime.substring(0, 10).replace(/-/g, "/")).getTime(),
        now = new Date(new Date().toLocaleDateString()).getTime();
    return start <= now && now <= end;
}
/* 判断折扣券是否即将到期 */
export const isDue = (endTime) => {
    if (endTime == 0) return false;
    let end = new Date(endTime.substring(0, 10).replace(/-/g, "/")).getTime(),
        now = new Date().getTime();
    return (now + 86400 * 30 * 1000) > end;
}
/* 返回优惠券使用场景提示 */
export const useCodeMsg = (goodsLimit) => {
    let msg = "";
    if (goodsLimit.limited) {
        msg += "仅限";
        goodsLimit.goodsDiscountLimits.map((item) => {
            if (item.usageGoodsType == 0) {
                msg += "开通" + item.goodsName;
            } else if (item.usageGoodsType == 1) {
                msg += item.goodsName;
            }
        })
        msg += "使用";
    }
    return msg;
}
/* 优惠券列表过滤，拆分成可用列表，和不可用列表 */
export const codeListFilters = (goodsLimit, usageGoodsType, goodsId) => {
    let data = {
        newList: [],
        availableQuantity: 0,
    }
    goodsLimit && goodsLimit.map(item => {
        let usable = false;
        let useTimeMsg = "";
        if (item.goodsLimit.limited) {
            item.goodsLimit.goodsDiscountLimits &&
                item.goodsLimit.goodsDiscountLimits.map((item) => {
                    if (item.usageGoodsType == usageGoodsType && item.goodsId == goodsId) {
                        usable = true;
                    }
                })
        } else {
            usable = true;
        }
        if (item.endValidTime.indexOf("2099-12-30") > -1) {
            useTimeMsg = "永久有效"
        } else {
            useTimeMsg = `有效期至${item.endValidTime.substring(0, 10)}`;
        }
        if (!isValidity(item.startValidTime, item.endValidTime)) {
            usable = false;
            useTimeMsg = `开始时间${item.startValidTime.substring(0, 10)}`;
        }
        item.useTimeMsg = useTimeMsg;
        if (usable) {
            data.newList.unshift(item);
            data.availableQuantity += 1;
        } else {
            data.newList.push(item);
        }
    })
    return data;
}

/* 产生m不超过16位随机数 */
export const randomFunc = (m) => {
    m = m > 16 ? 16 : m;
    var num = Math.random().toString();
    if (num.substr(num.length - m, 1) === '0') {
        return rand(m);
    }
    return num.substring(num.length - m);
}

/**
 * 若被嵌入iframe，采用postMessage跳转
 */
export const jumpPage = (path) => {
    if (path.includes("platform.html")) return;
    if(window.frames.length != parent.frames.length) {
        window.top.postMessage({ type: "route", path }, "*");
    } else {
        location.pathname = path;
    }
}