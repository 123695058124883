import { openStatus, userStatus, webLogout } from "src/static/services/website";
import { skipFunc } from "src/static/utils/webskipUtils.js";
import { ClickTrack } from 'src/static/utils/eventTrack';
import { jumpPage } from "src/static/utils/utils.js";

let main = {
  token: "",
  phone: "",
  init: function () {
    // this.getTokenFunc();
    this.bindEvent();
  },
  bindEvent: function () {
    let $this = this;
    $(".secondIntroduce").on("click", function () {
      ClickTrack("渠道合作-立即体验-点击查看", "点击", "点击查看");
      jumpPage("/experienceLogin.html");
    });
    $(".login-out").on("click", async function () {
      await webLogout();
      // window.location.href = window.location.href;
      window.location.reload();
    });
    $(".login-system").on("click", function () {
      $this.getStatus();
    });
    $(".affirmRenewal").on("click", function () {
      // window.location.href = "renewalSystem.html";
      jumpPage("/renewalSystem.html");
    });
    $(".login-experience").on("click", function () {
      $this.getUserStatus();
    });
  },
  getTokenFunc: async function () {
    this.token = localStorage.getItem("super-token");
    this.phone = localStorage.getItem("phone");
    if (this.token && this.token.length > 10 && this.phone != null) {
      this.phone = "" + this.phone;
      let telphone = this.phone.substr(0, 3) + "****" + this.phone.substr(7); //设置手机号中间四位为隐藏

      const res = await openStatus();
      const status = res.results.status;
      let stateObj = {
        "-1": () => {
          $(".login-system").html("购买正式");
        },
        1000: () => {
          $(".login-system").html("进入系统");
          $(".login-experience").hide();
        },
        900: () => {
          $(".headStyle .phone-box .login-system").hide();
          $(".headStyle .phone-box .affirmRenewal").show();
        },
      };
      if (status in stateObj) {
        stateObj[status]();
      } else {
        $(".login-system").html("继续订单");
      }
      $(".headStyle .phone-box span").html(telphone);
      $(".headStyle .state-btn .phone-box").show();
    } else {
      $(".headStyle .state-btn .not-login").show();
    }
  },
  //显示冻结信息
  freezeMsg() {
    ELEMENT.MessageBox.confirm(
      "实名认证流程已冻结,请联系客服人员：18958077257",
      {
        closeOnClickModal: false,
        confirmButtonText: "我知道啦",
        showCancelButton: false,
        center: true,
      }
    )
      .then(() => { })
      .catch(() => { });
  },
  //获取正式版系统开通状态
  async getStatus() {
    const res = await openStatus();
    if (res.state == 200) {
      if (res.results.status == "3") {
        this.freezeMsg();
      } else {
        skipFunc(res.results);
      }
    }
  },
  //获取体验开通状态
  async getUserStatus() {
    const res = await userStatus();
    if (res.state == 200) {
      if (res.results.status == -1) {
        // window.location.href = "experienceLogin.html";
        jumpPage("/experienceLogin.html");
      } else if (res.results.status == 9) {
        ELEMENT.MessageBox.confirm("您的测试时间已结束，请购买正式版系统", {
          closeOnClickModal: false,
          confirmButtonText: "购买正式系统",
          center: true,
        })
          .then(() => {
            // window.location.href = "chooseDeloy.html";
            jumpPage("/chooseDeloy.html");
          })
          .catch(() => { });
      } else if (res.results.status == 1000) {
        ELEMENT.MessageBox.confirm("您已开通测试系统，可直接进入系统", {
          closeOnClickModal: false,
          showCancelButton: false,
          confirmButtonText: "进入我的超级码®云",
          center: true,
        })
          .then(() => {
            window.open(
              `${res.results.value
              }/apiInterface/interface/hydra-user/api/v1/user/refresh-and-redirect?token=${localStorage.getItem(
                "super-token"
              )}`
            );
          })
          .catch(() => { });
      }
    }
  },
};

main.init();

// 平台简介跳转
$(function () {
  if (window.top !== window.self) {
    window.top.postMessage({ type: "sizeHeight", height: document.body.clientHeight }, "*")
  }
  $(".firstIntroduce").on("click", function () {
    ClickTrack("渠道合作-登录系统-点击查看", "点击", "点击查看");
    jumpPage("/login.html");
  });
  $(".tab-btn")
    .eq(0)
    .on("click", function () {
      $(".platformJump").css("display", "none");
    });
  $(".thirdIntroduce").on("click", function () {
    ClickTrack("渠道合作-立即购买-点击查看", "点击", "点击查看");
    jumpPage("/chooseDeloy.html");
    // window.location.href = "chooseDeloy.html";
  });

  //  平台核心竞争力
  $(".btn").eq(0).addClass("tapAdd");
  $(".btn").click(function () {
    // $(this).siblings().removeClass("activeHead");
    $(".btn").removeClass("tapAdd");
    $(this).addClass("tapAdd");
  });

  $(".firstTap").click(function () {
    $(".cardContent").css("display", "block");
    $(".nationalTeac").css("display", "none");
    $(".development").css("display", "none");
  });
  $(".secondTap").click(function () {
    $(".cardContent").css("display", "none");
    $(".nationalTeac").css("display", "block");
    $(".development").css("display", "none");
  });
  $(".thirdTap").click(function () {
    $(".cardContent").css("display", "none");
    $(".nationalTeac").css("display", "none");
    $(".development").css("display", "block");
  });

  // channel
  $(".firstIntroduce").mouseenter(function () {
    $(".seeIntroduce").addClass("addIntro");
    $(".introduce").attr("src", "/static/images/icon/icon2/mouseIntro.png");
  });
  $(".firstIntroduce").mouseleave(function () {
    $(".seeIntroduce").removeClass("addIntro");
    $(".introduce").attr("src", "/static/images/icon/open.png");
  });

  $(".secondIntroduce").mouseenter(function () {
    $(".seeIntroduceSecond").addClass("addIntro");
    $(".immediately").attr(
      "src",
      "/static/images/icon/icon2/mouseExperience.png"
    );
  });
  $(".secondIntroduce").mouseleave(function () {
    $(".seeIntroduceSecond").removeClass("addIntro");
    $(".immediately").attr("src", "/static/images/icon/total.png");
  });

  $(".thirdIntroduce").mouseenter(function () {
    $(".seeIntroduceThird").addClass("addIntro");
    $(".caseShow").attr("src", "/static/images/icon/icon2/mouseCase.png");
  });
  $(".thirdIntroduce").mouseleave(function () {
    $(".seeIntroduceThird").removeClass("addIntro");
    $(".caseShow").attr("src", "/static/images/icon/icon2/guide.png");
  });

  $(".tab-btn")
    .eq(1)
    .click(function () {
      $(".banner").css("display", "none");
      $(".channel").css("display", "none");
      $(".advantage").css("display", "none");
      $(".bannerImg").css("display", "block");
      $(".deloyCont").css({ display: "block", display: "flex" });
      $(".allDeloyCont").css("display", "block");
      $(".caseShowJump").css("display", "none");
    });

  $(".tab-btn")
    .eq(0)
    .click(function () {
      $(".banner").css("display", "block");
      $(".channel").css({ display: "block", display: "flex" });
      $(".advantage").css("display", "block");
      $(".bannerImg").css("display", "none");
      $(".allDeloyCont").css("display", "none");
    });
});

$(function () {
  const url = window.location.pathname;
  if (url.indexOf("productShow.html") > 0) {
    $(".naem_productShow").addClass("activeHeadHome");
  } else if (url.indexOf("caseShow.html") > 0) {
    $(".naem_caseShow").addClass("activeHeadHome");
  } else if (
    url.indexOf("aboutUs.html") > 0 ||
    url.indexOf("aboutDetail.html") > 0
  ) {
    $(".naem_aboutUs").addClass("activeHeadHome");
  } else if (url.indexOf("platform.html") > 0) {
    $(".naem_channel").addClass("activeHeadHome");
  } else {
    $(".naem_home").addClass("activeHeadHome");
  }
});

// 合作共赢
$(function () {
  $(".contTop div").click(function () {
    $(this).addClass("active").siblings().removeClass("active");
    let index = $(this).index();
    $(".cooMain .imgMain .showImg").eq(index).show().siblings().hide();
  });
});

// 在线咨询
//隐藏在线咨询
$(function () {
  // const interval = setInterval(() => {
  //   const $newBridge = $("#newBridge");
  //   if ($newBridge.length > 0) {
  //     $newBridge.hide();
  //     clearInterval(interval);
  //   }
  // }, 100);
  //

  $("#adviceOnline").on("click", function (event) {
    // if ($("#nb_invite_ok").length > 0) {
    //   $("#nb_invite_ok").click();
    // }
    // if (window.location.origin == "https://www.chaojima.com") {
    //   event.preventDefault();
    //   baiduStatistics();
    //   $("#newBridge").show();
    //   $("#nb_invite_ok").trigger("click");
    // } else {
    //   alert("不是正式环境,没有开启在线咨询");
    // }
    // window.open(
    //   "http://p.qiao.baidu.com/cps/chat?siteId=10120001&userId=6356299"
    // );
  });
});

function baiduStatistics() {
  setTimeout(function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?3d49d3840e69d771b002185ab08a3c25";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  }, 100);
}

// 回到顶部
$(function () {
  $("#gotoTop").hide(); //隐藏go to top按钮
  $(window).on("scroll", function () {
    var scrollHeight = $(document).height() * 0.02;
    if ($(this).scrollTop() > scrollHeight) {
      $("#gotoTop").fadeIn();
    } else {
      $("#gotoTop").fadeOut();
    }
  });

  /**
   * 回到顶部
   */
  $("#gotoTop img").click(function () {
    $("html ,body").animate(
      {
        scrollTop: 0,
      },
      300
    );
    return false;
  });
});

// $(function () {
//   // 了解超级码  -------------   团队优势
//   $(".about-item5 .about-part1-group-text").first().addClass("block");
//   $(".about-item5 .item-dec .item-li").first().addClass("active");
//   $(".item-dec").on("click", "li", function () {
//     alert(111111111111);
//     var index = $(".item-dec li").index(this);
//     $(".item-dec .item-li").removeClass("active");
//     $(".about-part1-group-text").removeClass("block");
//     $($(".about-part1-group-text")[index]).addClass("block");
//     $($(".item-dec .item-li")[index]).addClass("active");
//   });
// });
